import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CFG, SpiderGraphData } from './models';
import RadarChart from './RadarChart';
import { Trait, TraitScore } from '../../models/applications';

interface AssessmentSumChartProps {
  scores: TraitScore[];
  traits: Trait[];
  showMappingLegend: boolean;
}

const AssessmentSumChart = ({
  scores,
  traits,
  showMappingLegend,
}: AssessmentSumChartProps) => {
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem('contentDirection');
  const wbhrTraits = scores.filter((item) => item.trait.group_id !== null);
  const customTraits = scores.filter((item) => item.trait.group_id === null);
  const getGroupData = useCallback(
    (evolve: number, engage: number, execute: number) => {
      if (customTraits.length === 0) {
        const totalLength = evolve + engage + execute;
        return [
          {
            label: 'Evolve',
            startPoint: 0,
            endPoint: evolve - 1,
            length: evolve / totalLength,
          },
          {
            label: 'Engage',
            startPoint: evolve,
            endPoint: evolve + engage - 1,
            length: engage / totalLength,
          },
          {
            label: 'Execute',
            startPoint: evolve + engage,
            endPoint: evolve + engage + execute - 1,
            length: execute / totalLength,
          },
        ];
      } else {
        return [
          {
            label: '',
            startPoint: 0,
            endPoint: 0,
            length: 1,
          },
        ];
      }
    },
    [customTraits],
  );

  const drawChart = useCallback(
    (cfg: Partial<CFG>) => {
      let evolve: number, engage: number, execute: number;
      evolve = engage = execute = 0;
      let axes: SpiderGraphData[];
      if (customTraits.length === 0) {
        axes = traits
          .map((trait, index) => {
            if (trait.group_id === 1) {
              evolve++;
            } else if (trait.group_id === 2) {
              engage++;
            } else if (trait.group_id === 3) {
              execute++;
            }

            const traitAssessmentResult = scores.find(
              (item) => item.trait.id === trait.id,
            );
            return {
              name:
                i18n.language === 'ar' && trait.name_ar
                  ? trait.name_ar
                  : trait.name,
              icon: null, // to view icon set name to '' and set icon
              value:
                (traitAssessmentResult?.sten_score_percentage ?? -100) / 100,
              group_id: trait.group_id,
            };
          })
          .sort(function (a, b) {
            return b.group_id - a.group_id;
          });
      } else {
        axes = customTraits
          // Sort custom Traits by ID
          .sort(function (a, b) {
            return b.trait.id - a.trait.id;
          })
          .map((score, index) => {
            // if custom traits count > 10 return name else return icon
            return {
              name:
                customTraits.length > 10
                  ? i18n.language === 'ar' && score.trait.name_ar
                    ? score.trait.name_ar
                    : score.trait.name
                  : '', // to view name set icon to null and set name
              icon:
                customTraits.length <= 10
                  ? i18n.language === 'ar'
                    ? score.trait.name_svg_ar
                      ? score.trait.name_svg_ar
                      : score.trait.name_svg
                      ? score.trait.name_svg
                      : score.trait.icon_svg
                    : score.trait.name_svg
                    ? score.trait.name_svg
                    : score.trait.icon_svg
                  : null,
              value: (score?.sten_score_percentage ?? -100) / 100,
              group_id: 0,
            };
          });
      }

      const groupData = getGroupData(evolve, engage, execute);

      RadarChart.draw('#chart', [axes], cfg, axes.length, groupData);
    },
    [customTraits, getGroupData, traits, scores, i18n.language],
  );

  useEffect(() => {
    drawChart({});
  }, [direction, drawChart]);

  return (
    <section className="assessment-sec pdf-style page-break">
      <h2>{t('Assessment Summary')}</h2>
      <div className="assessment-chart-wrap">
        <div className="chart-inner" id="chart"></div>
        <div className="summary-text">
          {customTraits.length === 0 ? (
            <p>{t('The E3 model provides')}</p>
          ) : customTraits.length <= 10 && showMappingLegend ? (
            <div className="">
              <h6>{t('Competency Mapping Legend')}</h6>
              <table className="table mb-0 custom-trait-table">
                <thead className="">
                  <th scope="col fex" />
                  {wbhrTraits.map((item) => {
                    return (
                      <th scope="col flex" style={{ verticalAlign: 'unset' }}>
                        <span className="traits-table-text graph-title d-block">
                          {item.trait.name}
                        </span>
                        <span className="graph-title d-block">
                          <img height={20} src={item.trait.icon} alt="" />
                        </span>
                      </th>
                    );
                  })}
                </thead>
                <tbody>
                  {customTraits.map((customItem, traitIndex) => (
                    <tr
                      className={`${
                        traitIndex % 2 !== 0 ? 'bg-teal-light' : ''
                      }`}
                    >
                      <th className="flex" style={{ height: '40px' }}>
                        <span className="graph-title d-block mx-2">
                          <img width={25} src={customItem.trait.icon} alt="" />
                        </span>
                        <span className="graph-title d-block">
                          {i18n.language === 'ar' &&
                          customItem.trait.display_name_ar
                            ? customItem.trait.display_name_ar
                            : customItem.trait.display_name}
                        </span>
                      </th>
                      {wbhrTraits.map((item: any) => {
                        let is_match = customItem.trait.score_mapping.find(
                          (scoreItem: any) => scoreItem.id === item.trait.id,
                        );
                        if (is_match) {
                          return (
                            <td>
                              <span className="graph-title d-block">
                                <img
                                  height={15}
                                  src="/assets/img/check-mark.svg"
                                  alt=""
                                />
                              </span>
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      })}
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default AssessmentSumChart;
