import axios from 'axios';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../components/Header';
import Loader from '../../components/Loader/Loader';
import StaticFooter from '../../components/StaticFooter/StaticFooter';
import { BASE_URL, PATH } from '../../constants/variables';
import { Application, Trait, TraitScore } from '../../models/applications';
import { User } from '../../models/user';
import '../../components/Report/Print.css';
import { withTranslation } from 'react-i18next';
import { Scope, TranslateOptions } from 'i18n-js';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { CareerDriver } from '../../models/careerDrivers';
import {
  Cover,
  PrintFooter,
  StrengthBlock,
  CareerDriversBlock,
  AssessmentSumProgressBlock,
  AssessmentSumChart,
} from '../../components/Report';
import queryString from 'query-string';
import setToken from '../../setToken';

interface ManagerReportProps extends RouteComponentProps<{ id?: string }> {
  t: (scope: Scope, options?: TranslateOptions) => string;
}

interface ManagerReportState {
  traits: Trait[];
  traitScores: TraitScore[];
  leastImportantCareerDrivers: CareerDriver[];
  mostImportantCareerDrivers: CareerDriver[];
  loading: boolean;
  saving: boolean;
  me: User | null;
  appliedDate: null | Application['applied'];
}

class ManagerReport extends React.Component<
  ManagerReportProps,
  ManagerReportState
> {
  state: ManagerReportState = {
    traits: [],
    traitScores: [],
    leastImportantCareerDrivers: [],
    mostImportantCareerDrivers: [],
    loading: false,
    saving: false,
    me: null,
    appliedDate: null,
  };

  applied: Application['applied'] | null = null;

  companyData: Application['job']['company'] | any = [];

  componentDidMount() {
    let token = localStorage.getItem('token');
    if (!token) {
      // @ts-ignore
      ({ token } = queryString.parse(this.props.location.search));
    }
    if (token) {
      setToken(token);
    }

    const appId = this.props.match.params.id;
    axios
      .get<Application>(`${BASE_URL}${PATH}applications/${appId}/`)
      .then((res) => {
        const candidateId = res.data.candidate_id;
        const traitScores: TraitScore[] = res.data.trait_scores;
        const traits: Trait[] = res.data.traits;
        this.applied = res.data.applied;
        this.companyData = res.data.job.company;

        axios
          .all([
            axios.get(
              `${BASE_URL}${PATH}applications/${appId}/most_important_drivers/`,
            ),
            axios.get(
              `${BASE_URL}${PATH}applications/${appId}/least_important_drivers/`,
            ),
            axios.get(`${BASE_URL}/api/auth/candidates/${candidateId}`),
          ])
          .then((res) => {
            this.setState({
              traits: traits,
              traitScores: traitScores,
              leastImportantCareerDrivers: res[1].data,
              mostImportantCareerDrivers: res[0].data,
              me: res[2].data,
              appliedDate: this.applied,
            });
          });
      });
  }

  print() {
    window.print();
  }

  render() {
    const name = this.state.me
      ? `${this.state.me.first_name} ${this.state.me.last_name}`
      : null;
    const { t } = this.props;

    if (!this.state.traitScores.length || this.state.loading) {
      return <Loader />;
    }

    const direction = localStorage.getItem('contentDirection');

    return (
      <div
        id="pdf-root"
        className={`container report text-align-start ${
          direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
        }`}
      >
        <Header
          // @ts-ignore
          companylogo={'/assets/img/logo.png'}
          isDashboardHeader
        />
        <Cover
          name={name}
          applied={this.applied}
          title={t('Line Manager Report')}
          showConfidentialityNote={true}
        />
        <div className="assessment-sec page-break">
          <StrengthBlock
            data={this.state.traitScores}
            name={!!name ? name : ''}
            isLineManager={true}
          />
        </div>

        {!!this.state.traitScores.length && (
          <>
            <AssessmentSumChart
              scores={this.state.traitScores}
              traits={this.state.traits}
              showMappingLegend={this.companyData.show_mapping_legend}
            />

            <AssessmentSumProgressBlock
              scores={this.state.traitScores}
              isAppendixPage={false}
            />
          </>
        )}

        <CareerDriversBlock
          name={this.state.me ? this.state.me.first_name : ''}
          dataMost={this.state.mostImportantCareerDrivers}
          dataLeast={this.state.leastImportantCareerDrivers}
        />

        {this.companyData?.show_appendix && (
          <AssessmentSumProgressBlock
            scores={this.state.traitScores}
            isAppendixPage={true}
          />
        )}

        <section className="row justify-content-center candidate-report">
          <button
            type="button"
            onClick={this.print}
            className="print-button test-result-block-button theme-bg"
          >
            <i className="fas fa-download"></i> {t('Download')}
          </button>
        </section>
        <StaticFooter />
        <PrintFooter name={name} />
      </div>
    );
  }
}

export default withTranslation()(ManagerReport);
